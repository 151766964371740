export default {
  colors: {
    black: "#000",
    white: "#fff",

    accent: "#C8102E",

    variants: {
      hover: {
        accent: "#E00027",
      },

      dark: {
        id: "dark",

        background: "#050505",

        button: {
          background: {
            default: "#1D1D1D",
            active: "#E6E6E6",
          },
          border: "#323232",
          focus: "#C8102E",
          text: {
            default: "#BABABA",
            active: "#1D1D1D",
            hover: "#E6E6E6",
          },
        },

        logo: "#FFF",

        navigation: {
          default: "#ABABAB",
          active: "#E6E6E6",
        },

        text: {
          heading: "#E6E6E6",
          body: "#BABABA",
          subtle: "#797979",
        },
      },

      light: {
        id: "light",

        background: "#EBEBEB",

        button: {
          background: {
            default: "#FFFFFF",
            active: "#2D2D2D",
          },
          border: "#F4F4F4",
          borderDarker: "#DBDBDB",
          focus: "#4E4E4E",
          text: {
            default: "#4E4E4E",
            active: "#F6F6F6",
            hover: "#2D2D2D",
          },
        },

        highlight: {
          background: "#E6E6E6",
          border: "#E8E8E8",
        },

        logo: "#000",

        navigation: {
          default: "#777777",
          active: "#2D2D2D",
        },

        timeline: {
          text: {
            heading: {
              default: "#4E4E4E",
              active: "#2D2D2D",
            },
            body: {
              default: "#969696",
              active: "#4E4E4E",
            },
          },
        },

        text: {
          heading: "#2D2D2D",
          body: "#4E4E4E",
        },
      },
    },
  },

  motion: {
    contentVariants: {
      initial: { opacity: 0, y: 20 },
      enter: ({ delay } = {}) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: delay || 1,
          type: "spring",
          damping: 50,
          stiffness: 150,
        },
      }),
    },
  },

  screens: {
    sm: 768,
    custom: {
      mobile: 450,
    },
    md: 1024,
    lg: 1440,
  },
}
