import React from "react"
import { Helmet } from "react-helmet"

// LMT izstrādāts centralizēts Sīkdatņu paziņojums

function waitForGlobal(name, timeout = 500) {
  return new Promise((resolve, reject) => {
    let waited = 0

    function wait(interval) {
      setTimeout(() => {
        waited += interval
        if (window[name] !== undefined) {
          return resolve()
        }
        if (waited >= timeout * 1000) {
          return reject({ message: "Timeout" })
        }
        wait(interval * 2)
      }, interval)
    }

    wait(25)
  })
}

export default function CookiesConsentProxy() {
  const [scriptLoaded, setScriptLoaded] = React.useState(false)

  React.useEffect(() => {
    if (window) {
      if (window.Cookies && window.Cookies.isStatisticsCookieAllowed) {
        setScriptLoaded(true)
      }
      waitForGlobal("Cookies").then(() => {
        setScriptLoaded(true)
      })
    }
  }, [])

  const [cookiesOptIn, setCookiesOptIn] = React.useState({
    functional: true,
    analytical: false,
    marketing: false,
  })
  React.useEffect(() => {
    if (scriptLoaded && window.Cookies) {
      setCookiesOptIn({
        functional: window.Cookies.isTechnicalCookieAllowed(),
        analytical: window.Cookies.isStatisticsCookieAllowed(),
        marketing: window.Cookies.isMarketingCookieAllowed(),
      })
    }
  }, [scriptLoaded])

  const scripts = React.useMemo(() => {
    return process.env.NODE_ENV === "production" ? (
      <>
        {/* {cookiesOptIn.analytical && ()} */}

        {cookiesOptIn.marketing && (
          <>
            <Helmet>
              {/* Hotjar Tracking Code for www.lmt.lv */}
              <script key="hotjar-inline">{`
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1777976,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}</script>
            </Helmet>

            <Helmet>
              {/* <!-- Google Tag Manager --> */}
              <script key="gtm-inf-inline">{`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f
              );
              })(window,document,'script','dataLayer','GTM-PMRQ656');
              `}</script>
            </Helmet>
          </>
        )}
      </>
    ) : (
      undefined
    )
  }, [/* cookiesOptIn.analytical, */ cookiesOptIn.marketing])

  const cookiesVersion = Math.floor(Date.now() / 1000 / 60 / 15) // katras 15 minūtes

  return typeof window !== "undefined" ? (
    <>
      <Helmet>
        <link
          key="lmt-cookies-style"
          href={`https://cookies.lmt.lv/css/cookie.css?v=${cookiesVersion}`}
          media="all"
          rel="stylesheet"
          type="text/css"
        />
        {typeof window !== "undefined" && (
          <script
            key="lmt-cookies-script"
            type="text/javascript"
            src={`https://cookies.lmt.lv/js/cookie.js?v=${cookiesVersion}`}
          />
        )}
      </Helmet>

      {scripts}

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PMRQ656"
          title="Google Tag Manager (noscript)"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
    </>
  ) : null
}
